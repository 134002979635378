<template>
  <div id="flightCheck">
    <header-bar title="飞行检查" />
    <section>
      <h3><span>*</span><i>图片上传</i></h3>
      <div class="inner">
        <van-uploader
          v-model="fileList"
          preview-size="100px"
          :after-read="afterFileRead"
          multiple
          accept="image/*"
        />
      </div>
    </section>

    <section>
      <h3><span>*</span><i>检查地点</i></h3>
      <div class="inner">
        <div class="checkArea grey9" @click="isShowCheckAreaPopup = true">
          <span class="oneClamp">{{ checkAreaItem.name || '请选择检查地点' }}</span>
          <img src="../assets/images/arrow_r_grey.png" alt="">
        </div>
      </div>
      <h3><span>*</span><i>检查评分</i></h3>
      <div class="inner">
        <ul class="rateList">
          <li v-for="item in rateList" :key="item.key">
            <span class="grey9">{{ item.label }}</span>
            <rate-star @onChange="onChange(item.key, $event)" />
          </li>
        </ul>
      </div>
    </section>

    <section>
      <h3><span>*</span><i>检查描述</i></h3>
      <div class="inner">
        <textarea v-model="params.description" placeholder="请对检查情况进行描述" maxlength="500" />
      </div>
    </section>

    <confirm-popup
      title="请选择检查地点"
      :is-show="isShowCheckAreaPopup"
      :data="participant"
      @clickCancel="isShowCheckAreaPopup = false"
      @clickConfirm="confirmCheckArea"
    />
    <footer-bar :disabled="isDisabled" @clickConfirm="clickConfirm" />
  </div>
</template>

<script>
// import { } from 'vuex'
import HeaderBar from '../components/HeaderBar/index';
import RateStar from '@/components/RateStar/index';
import FooterBar from '@/components/FooterBar/index';
import ConfirmPopup from '../components/ConfirmPopup/index';
export default {
  name: 'FlightCheck',
  components: { ConfirmPopup, FooterBar, RateStar, HeaderBar },
  props: [],
  data() {
    return {
      params: {
        effect: 5,
        kpi_reach: 5,
        staff_status: 5,
        plan_accord: 5,
        description: ''
      },
      fileList: [],
      rateList: [
        { label: '现场效果', key: 'effect' },
        { label: 'KPI达成', key: 'kpi_reach' },
        { label: '人员状态', key: 'staff_status' },
        { label: '方案符合度', key: 'plan_accord' }
      ],
      isShowCheckAreaPopup: false,
      checkAreaItem: {},
      info: {
        participant: []
      }
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    isDisabled() {
      return this.fileList.length === 0 || !this.checkAreaItem.id || !this.params.description;
    },
    participant() {
      return this.info.participant.map(item => {
        return {
          id: item.id,
          name: item.branch_name
        };
      });
    }
  },
  watch: {},
  created() {
    this.getProjectDetail();
  },
  mounted() {},
  methods: {
    // 获取项目详情
    getProjectDetail() {
      this.http.$post(this.$api.getProDetail, {
        id: this.id
      }).then(res => {
        this.info = res.data.info;
      });
    },
    afterFileRead(file) {
      if (Array.isArray(file)) {
        file.forEach(item => {
          item.status = 'uploading';
          item.message = '上传中...';
          this.upload(item);
        });
      } else {
        file.status = 'uploading';
        file.message = '上传中...';
        this.upload(file);
      }
    },
    upload(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('token', this.$store.getters.token);
      this.http.post(
        process.env.VUE_APP_UPLOAD_API,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Auth: 'LebEz8nTG1Zi'
          }
        }).then(res => {
        file.status = 'done';
        file.message = '';
        file.url = res.data.file_url;
        file.id = res.data.file_id;
        console.log(this.fileList);
      });
    },
    onChange(key, value) {
      this.params[key] = value;
    },
    confirmCheckArea(item) {
      this.checkAreaItem = item;
      this.isShowCheckAreaPopup = false;
    },
    clickConfirm() {
      const files = this.fileList.map(item => { return item.id; });
      console.log(files);
      const params = {
        project_id: this.info.id,
        branch_id: this.checkAreaItem.id,
        files: files.join(),
        ...this.params
      };
      console.log(params);
      const toast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      this.http.$post(this.$api.submitCheck, params)
        .then(res => {
          this.$toast({
            message: '提交成功',
            duration: 1000
          });
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        }).finally(() => {
        toast.clear();
        });
    }
  }

};
</script>
<style lang="scss">
#flightCheck {
  .van-uploader__upload{
    background: $color-bg;
    border-radius: 12px;
  }
  .van-uploader__preview{
    .van-image{
      overflow: hidden;
      border-radius: 12px;
    }
    .van-uploader__preview-delete{
      background: #c2c2c2;
      width: 32px;
      height: 32px;
      right: 12px;
      top: 12px;
      border-radius: 50%;
      .van-uploader__preview-delete-icon{
        font-size: 28px;
        top: 2px;
        left: 2px;
        transform: scale(1);
      }
    }
  }
  .van-uploader__preview:nth-of-type(3n) {
    margin-right: 0;

  }
}
</style>
<style lang="scss" scoped>
#flightCheck{
  padding-bottom: 100px;
  .van-uploader__preview:nth-of-type(3n) {
    margin-right: 0;
  }
  section{
    padding: 48px 0;
    background: #fff;
    margin-bottom: 16px;
    h3{
      padding: 0 32px 32px;
      line-height: 44px;
      span{
        color: $color-danger;
        margin-right: 8px;
      }
      i{
        font-size: 32px;
      }
    }
    .inner{
      padding: 0 56px;

      textarea{
        border: 2px $border-color solid;
        border-radius: $border-radius;
        width: 100%;
        padding: 14px 26px;
        height: 212px;
        box-sizing: border-box
      }
      .checkArea{
        height: 64px;
        line-height: 64px;
        padding: 0 20px 0 26px;
        margin-bottom: 48px;
        border: 2px $border-color solid;
        border-radius: $border-radius;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 580px;
        img{
          width: 40px;
          height: 40px;
        }
      }
      .rateList{
        li{
          display: flex;
          align-items: center;
          line-height: 40px;
          &:not(:last-of-type){
            margin-bottom: 16px;
          }
          span{
            width: 178px;
          }
        }
      }
    }
  }
}
</style>
